
<template>
  <div class="right jackpot_bg shadow">
    <div class="z_wrapp">
      <Giveaway />

      <div class="jackpot_templ">
        <img
            v-if="game.state === 'default' || game.state === 'timer'"
            src="img/barrel.png"
            class="barrel" alt=""
        />

        <img v-else-if="game.state === 'roulette'" src="img/fire_2.gif" class="fire" alt=""/>

        <img v-else-if="game.state === 'ending'" src="img/fire.png" class="fire fire_2" alt=""/>

        <div
            v-if="game.state === 'default' || game.state === 'timer'"
            class="top_row" :class="[game.state !== 'default' && game.state !== 'timer' ? 'top_row_2': '']"
        >
          <div>
            <div class="total_winner_item">
              <h3>Bank:</h3>
              <p><animated-number :value="game.bank" :formatValue="formatToBank" :duration="1000"/></p>
            </div>
          </div>
          <div>
            <div class="counter_templ">
              <div class="counter_wrapp"><p id="jackpot_countdown_description">To the end</p>
                <p class="countdown" id="jackpot_countdown">{{ game.minutes }}:{{ game.seconds }}</p></div>
              <div class="button_wrapp_3"><a style="cursor: pointer" @click="showDepositSkins = true"
                                             class="red_pill"
                                             id="jackpot_make_deposit"><span>make deposit</span></a></div>
              <div class="two_cols_5">
                <div><p>MIN: ${{ game.min_bet.toFixed(2) }}</p></div>
                <div><p>FEE: {{ game.fee }}%</p></div>
              </div>
            </div>
          </div>
          <div>
            <div class="total_winner_item">
              <h3>YOUR PERCENT:</h3>
              <p v-if="bet.sum > 0"><animated-number :value="(bet.sum / game.bank) * 100" :formatValue="formatToChance" :duration="1000"/></p>
              <p v-else>0.00%</p>
            </div>
          </div>
        </div>

        <div v-else-if="game.state === 'roulette'" class="top_row">
          <div>
            <div class="total_winner_item">
              <h3>Won:</h3>
              <p>${{ winner.bank }}</p>
            </div>
          </div>
          <div>
            <div class="total_winner_item">
              <h3>with a chance:</h3>
              <p>{{ winner.chance }}%</p>
            </div>
          </div>
        </div>

        <div v-else-if="game.state === 'ending'" class="total_winner total_winner_2">
          <div class="total_winner_header">
            <h3>winner:</h3>
            <h2>{{ winner.user.username }}</h2>
          </div>
          <div class="winner_three_cols">
            <div>
              <div class="total_winner_item">
                <h3>won:</h3>
                <p>${{ winner.bank }}</p>
              </div>
            </div>
            <div>
              <div class="total_winner_avatar">
                <div class="img_box">
                  <img style="width: 34px;" :src="winner.user.avatar" alt=""/>
                </div>
              </div>
            </div>
            <div>
              <div class="total_winner_item">
                <h3>with a chance:</h3>
                <p>{{ winner.chance }}%</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="game.state === 'default' || game.state === 'timer'" class="users_slider_wrapp users_slider_wrapp_2">
          <img class="slider_shape" src="img/slider_shape.png" alt=""/>
          <div class="users_slider"></div>
        </div>

        <div v-else class="users_slider_wrapp">
          <img class="slider_shape" src="img/slider_shape.png" alt=""/>
          <div class="users_slider" id="jackpot_carousel">
            <div style="width: 8000px;">
              <div class="slide" v-for="(user, i) in game.avatars" :key="i" :id="i" style="float: left;">
                <div class="user_avatar_3">
                  <div class="img_box">
                    <img :src="user.avatar" alt="" style="width: 51px;">
                  </div>
                  <p class="sl_p">{{ user.chance }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="two_cols_4">
        <div v-if="lastWinner !== null">
          <div class="gamer_params_wrapp gamer_params_wrapp_2">
            <div>
              <div class="gamer_params">
                <div>
                  <div class="single_item">
                    <div class="draw_item">
                      <div>
                        <i class="cup_red_big"></i>
                      </div>
                      <div>
                        <h3>LAST <br>WINNER</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="items_2">
                    <div class="draw_item">
                      <div>
                        <i class="cub_red_big"></i>
                      </div>
                      <div>
                        <h3>ITEMS</h3>
                        <p>{{ lastWinner.items }}</p>
                      </div>
                    </div>
                    <div class="draw_item">
                      <div>
                        <i class="rub_red_big"></i>
                      </div>
                      <div>
                        <h3>BANK</h3>
                        <p>${{ lastWinner.sum.toFixed(2) }}</p>
                      </div>
                    </div>
                    <div class="draw_item">
                      <div>
                        <i class="percent_red_big"></i>
                      </div>
                      <div>
                        <h3>CHANCE</h3>
                        <p>{{ lastWinner.chance.toFixed(2) }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="user_wrapp_2">
                    <div>
                      <h3><a  target="_blank">{{ lastWinner.user.username }}</a></h3>
                    </div>
                    <div>
                      <div class="user_avatar red">
                        <a target="_blank">
                          <div class="img_box">
                            <img style="width: 34px;" :src="lastWinner.user.avatar" alt="">
                            <div class="lvl">{{ lastWinner.user.lvl }}</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="luckyWinner !== null">
          <div class="gamer_params_wrapp gamer_params_wrapp_2 yellow">
            <div>
              <div class="gamer_params gamer_params_2">
                <div>
                  <div class="single_item">
                    <div class="draw_item">
                      <div>
                        <i class="cor_yellow_big"></i>
                      </div>
                      <div>
                        <h3>BEST <br>PRIZE</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="items_2">
                    <div class="draw_item">
                      <div>
                        <i class="cub_yellow_big"></i>
                      </div>
                      <div>
                        <h3>ITEMS</h3>
                        <p>{{ luckyWinner.items }}</p>
                      </div>
                    </div>
                    <div class="draw_item">
                      <div>
                        <i class="rub_yellow_big"></i>
                      </div>
                      <div>
                        <h3>BANK</h3>
                        <p>${{ luckyWinner.sum.toFixed(2) }}</p>
                      </div>
                    </div>
                    <div class="draw_item">
                      <div>
                        <i class="percent_yellow_big"></i>
                      </div>
                      <div>
                        <h3>CHANCE</h3>
                        <p>{{ luckyWinner.chance.toFixed(2) }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="user_wrapp_2 user_wrapp_2_2">
                    <div>
                      <h3><a  target="_blank">{{ luckyWinner.user.username }}</a></h3>
                    </div>
                    <div>
                      <div class="user_avatar yellow">
                        <a target="_blank">
                          <div class="img_box">
                            <img style="width: 34px;" :src="luckyWinner.user.avatar" alt="">
                            <div class="lvl">{{ luckyWinner.user.lvl }}</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="thumbnails_2">
        <div class="thumb_2" v-for="bet of game.bets" :key="bet.id">
          <div class="winner_thumb_header">
            <div class="user_info_2">
              <div>
                <div class="user_avatar_4" @click="showBet(bet)">
                  <a target="_blank">
                    <div class="img_box">
                      <img :src="bet.user.avatar" alt="" style="width: 38px;">
                      <div class="lvl">{{ bet.user.lvl }}</div>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <p style="float: right">Tickets: from #{{ bet.bet.from.toFixed(0) }} to #{{ bet.bet.to.toFixed(0) }}</p>
                <h3 style="display: flex">
                  <a>{{ bet.user.username }}</a>
                </h3>
                <p v-if="bet.user.username.toLowerCase() !== 'бонусная ставка'">Chance: <span>{{ bet.bet.chance }}%</span></p>
              </div>
            </div>
            <div class="text_wrapp_3">
              <p>: <span>${{ bet.bet.sum.toFixed(2) }}</span></p>
            </div>
          </div>
          <div class="inner">
            <div class="history_goods_2">
              <div class="history_good tooltip" :style="`border-bottom: 2px solid #${item.background_color};`" v-for="item of bet.bet.items" :key="item.assetid">
                <div class="img_box">
                  <img
                      :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.icon_url}/360fx360f`"
                      alt="">
                </div>
                <div class="descript">
                  <p>${{ item.price.toFixed(2) }}</p>
                </div>
                <div class="tooltiptext">
                  {{ item.market_hash_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SelectedBet :selected-bet.sync="selectedBet" />
    <DepositSkins :show-deposit-skins.sync="showDepositSkins"/>
  </div>
</template>

<script>
import DepositSkins from "@/components/modals/DepositSkins";
import $ from 'jquery';
import Giveaway from "@/components/Giveaway";
import 'bootstrap';
import Swal from 'bootstrap-sweetalert';
import AnimatedNumber from "animated-number-vue";
import SelectedBet from "../../components/modals/SelectedBet";

export default {
  name: "Index",
  components: {
    SelectedBet,
    DepositSkins,
    Giveaway,
    AnimatedNumber
  },
  data() {
    return {
      game: {
        state: 'default',
        timeToStart: 90,
        fee: 0,
        min_bet: 0,
        bank: 0.00,
        bets: [],
        minutes: '',
        seconds: '',
        avatars: []
      },
      winner: {
        bank: '???',
        chance: '???',
        user: {
          username: '???',
          avatar: '???'
        }
      },
      bet: {
        sum: 0.00
      },
      lastWinner: null,
      luckyWinner: null,
      showDepositSkins: false,
      selectedBet: null
    }
  },
  mounted() {
    this.getConfig()
      .then(() => {
        this.parseTimer(this.game.timeToStart);
        this.getGame();
      })
  },
  methods: {
    pad(n) {
      if (n < 10)
        return "0" + n;
      return n;
    },
    parseTimer(time) {
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;

      this.game.minutes = this.pad(minutes);
      this.game.seconds = this.pad(seconds);
    },
    getGame() {
      this.$root.request('GET', '/games/game')
          .then((data) => {
            this.game.bank = data.game.bank;
            this.game.bets = data.bets;
            this.lastWinner = data.lastWinner;
            this.luckyWinner = data.luckyWinner;

            if (data.game.status === 0) {
              this.game.state = 'default';
            }

            if (data.game.status === 1) {
              this.game.state = 'timer';
            }

            if (data.game.status === 2 && !data.game.roulette) {
              this.game.state = 'roulette';
              this.game.avatars = data.game.avatars;

              this.startRoulette(data.game.winner);
            }

            if (data.game.roulette) {
              this.game.state = 'ending';

              this.winner = data.game.winner;
              this.game.avatars = data.game.avatars;

              setTimeout(() => {
                let t = 80;

                let scrollValue = -t * 80 + ($('#jackpot_carousel').outerWidth() / 2) - this.randomInteger(20, 40);

                $('#jackpot_carousel > div').css({
                  marginLeft: scrollValue
                })
              }, 500);
            }

            this.findMySumBet();
          })
    },
    findMySumBet() {
      if (this.$root.user !== null) {
        let price = 0.00;

        for (let bet of this.game.bets) {
          if (bet.user.id === this.$root.user.id) {
            price += bet.bet.sum;
          }
        }

        this.bet.sum = price;
      }
    },
    startRoulette(winner) {
      setTimeout(() => {
        this.$root.playSound('/sound/carousel.mp3');

        let t = 80;

        let scrollValue = -t * 80 + ($('#jackpot_carousel').outerWidth() / 2) - this.randomInteger(20, 40);

        $('#jackpot_carousel > div').animate({
          marginLeft: scrollValue
        }, 12000, 'swing');

        setTimeout(() => {
          this.$root.playSound('/sound/winner.mp3');

          this.game.state = 'ending';
          this.winner = winner;
        }, 12500);
      }, 500);
    },
    randomInteger(min, max) {
      let rand = min + Math.random() * (max - min)
      rand = Math.round(rand);
      return rand;
    },
    getConfig() {
      return new Promise((res) => {
        this.$root.request('GET', '/games/config')
            .then((data) => {
              this.game.timeToStart = data.time_to_start;
              this.game.fee = data.fee;
              this.game.min_bet = data.min_bet;

              res(true);
            })
      });
    },
    showModalWin(steamId, sum) {
      Swal({
        title: `You won ${sum.toFixed(2)}$`,
        text: "Congratulations on your win! Newcommers must be level 10 to withdraw items.",
        type: "success",
        showCancelButton: false,
//        confirmButtonText: "Browser",
//        cancelButtonText: "Steam-client",
      }, /*(isConfirm) => {
        if (isConfirm) {
          window.open(`https://steamcommunity.com/profiles/${steamId}/tradeoffers/`, '_blank');
        } else {
          window.open(`steam://openurl/https://steamcommunity.com/profiles/${steamId}/tradeoffers/`);
        }
      }*/);
    },
    formatToBank(value) {
      return `$${value.toFixed(2)}`
    },
    formatToChance(value) {
      return `${value.toFixed(2)}%`
    },
    showBet(bet) {
      if (!this.$root.user || this.$root.user.role !== 'admin') {
        return
      }

      this.selectedBet = bet

      $('#selectedBet').modal('show');
    }
  },
  sockets: {
    gameTimeToStart(time) {
      this.game.state = 'timer';
      this.parseTimer(time);
    },
    gameNewBet(data) {
      this.$root.playSound('/sound/bet.mp3');

      this.game.bank = data.game.bank;
      this.game.bets = data.bets;

      this.findMySumBet();
    },
    newGame(data) {
      this.game.bank = 0.00;
      this.game.bets = [];
      this.winner = {
        bank: '???',
        chance: '???',
        user: {
          username: '???',
          avatar: '???'
        }
      };
      this.game.avatars = [];
      this.game.state = 'default';

      this.luckyWinner = data.luckyWinner;
      this.lastWinner = data.lastWinner;

      this.parseTimer(this.game.timeToStart);
      this.findMySumBet();
    },
    startRoll(data) {
      this.game.state = 'roulette';
      this.game.avatars = data.avatars;
      this.game.win_ticket = data.win_ticket;

      this.startRoulette(data.winner);
    },
    showWinModal(data) {
      if (this.$root.user !== null && this.$root.user.steamId === data.steamId) {
        this.showModalWin(data.steamId, data.sum)
      }
    }
  }
}
</script>
