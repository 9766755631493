<template>
  <div class="wrapper" style="padding-bottom: 88px;">
    <Header/>
    <ResponsiveMenu/>
    <div class="content" style="padding-top: 88px;">
      <div class="content_templ">
        <Chat :online="online" />

        <router-view :online="online"/>
      </div>
    </div>
    <Footer/>
    <notifications />
  </div>
</template>

<script>
import $ from 'jquery';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';
import Header from "@/components/Header";
import ResponsiveMenu from "@/components/ResponsiveMenu";
import Footer from "@/components/Footer";
import Chat from "@/components/Chat";

export default {
  components: {
    Header,
    ResponsiveMenu,
    Footer,
    Chat
  },
  data() {
    return {
      bodyWidth: 0,
      online: 0
    }
  },
  name: 'App',
  mounted() {
    const vm = this;

    vm.bodyWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

    $(window).on('load', function() {
      vm.getSidebarHeight();
      $(".scrollbar").mCustomScrollbar();
    });

    $(window).resize(function () {
      vm.bodyWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

      $(".content").css({
        "padding-top": $(".header_site").height() + "px"
      });

      vm.getSidebarHeight();
    });
  },
  methods: {
    getSidebarHeight() {
      $(".wrapper").css({
        "padding-bottom": $(".footer_section").height() + "px"
      });

      if (this.bodyWidth >= 1300) {
        $("#chatUsers").css({
          "top": $(".sidebar_header").outerHeight() + "px",
          "max-height": $(window).height() - $(".sidebar_header").outerHeight() - $("#footerForm").outerHeight() - $(".header_site").outerHeight() + "px"
        });
      } else {
        $("#chatUsers").css({
          "top": "auto",
          "max-height": "auto"
        });
      }

      $("#chatMenu").css({
        "top": $(".header_site").height() + "px"
      });
    }
  },
  sockets: {
    online(online) {
      this.online = online;
    }
  }
}
</script>
