<template>
  <div id="fair" class="popup_deposit modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog modal-lg">
      <div class="popup_deposit_ins">
        <div class="popup_deposit_top">Check Fair</div>

        <div class="popup_deposit_row text_wrapp_3" style="display: block;">
          <h1 style="color: #c6a783">ROUND INFO</h1>
          <br>
          <p>Win ticket:<span> #{{ game.win_ticket.toFixed(0) }} (Total: {{ game.all_tickets.toFixed(0) }})</span></p>
          <br/>
          <p>Round number: <span>{{ game.round_number }}</span></p>
          <br/>
          <p>Hash: <span>{{ game.hash }}</span></p>
          <br/>

          <h1 style="color: #c6a783">HOW TO CHECK?</h1>
          <br/>

          <p>
            <span>Round hash being generated by this formula: sha256(Round number)</span>
          </p>
          <br>

          <p>
            <span>For this game: {{ game.round_number }}</span>
          </p>
          <br>

          <p>
            <span>You can put this info in any <a href="https://www.freeformatter.com/sha256-generator.html" target="_blank">sha256 generator</a></span>
          </p>
          <br>

          <p>
            <span>
              The winning ticket is calculated using the formula: Round number * All tickets
            </span>
          </p>
          <br>

          <p>
            <span>For this game: {{ game.round_number }} * {{ game.all_tickets.toFixed(0) }} = {{ game.win_ticket.toFixed(0) }}</span>
          </p>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: ['game', 'showFair'],
  mounted() {
    const vm = this;

    $('#fair').on('hidden.bs.modal', function () {
      vm.$emit('update:showFair', false);
    })
  },
  watch: {
    'showFair'() {
      if (this.showFair) {
        $('#fair').modal('show');
      } else {
        $('#fair').modal('hide');
      }
    }
  },
}
</script>