<template>
  <div v-if="game" class="right jackpot_bg shadow">
    <div class="z_wrapp">
      <div class="jackpot_templ">
        <img src="/img/fire.png" class="fire fire_2" alt=""/>

        <div class="total_winner total_winner_2">
          <div class="total_winner_header">
            <h3>winner:</h3>
            <h2>{{ game.winner.user.username }}</h2>
          </div>
          <div class="winner_three_cols">
            <div>
              <div class="total_winner_item">
                <h3>won:</h3>
                <p>${{ game.winner.bank }}</p>
              </div>
            </div>
            <div>
              <div class="total_winner_avatar">
                <div class="img_box">
                  <img style="width: 34px;" :src="game.winner.user.avatar" alt=""/>
                </div>
              </div>
            </div>
            <div>
              <div class="total_winner_item">
                <h3>with a chance:</h3>
                <p>{{ game.winner.chance }}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="thumbnails_2">
        <div class="thumb_2" style="padding: 10px">
          <div class="text_wrapp_3">
            <p>Win ticket:<span> #{{ game.win_ticket.toFixed(0) }} (Total: {{ game.all_tickets.toFixed(0) }})</span></p>
            <br/>
            <p>Round number: <span>{{ game.round_number }}</span></p>
            <br/>
            <p>Hash: <span>{{ game.hash }}</span></p>
            <a class="red_pill" @click="showFair = true">
              <span>Check online</span>
            </a>
          </div>
        </div>
      </div>

      <div class="thumbnails_2">
        <div class="thumb_2" v-for="bet of bets" :key="bet.id">
          <div class="winner_thumb_header">
            <div class="user_info_2">
              <div>
                <div class="user_avatar_4">
                  <a target="_blank">
                    <div class="img_box">
                      <img :src="bet.user.avatar" alt="" style="width: 38px;">
                      <div class="lvl">{{ bet.user.lvl }}</div>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <p style="float: right">Tickets: from #{{ bet.bet.from.toFixed(0) }} to #{{ bet.bet.to.toFixed(0) }}</p>
                <h3 style="display: flex"><a target="_blank">{{ bet.user.username }}</a></h3>
                <p>Chance: <span>{{ bet.bet.chance }}%</span></p>
              </div>
            </div>
            <div class="text_wrapp_3">
              <p>Contributed items in the amount: <span>${{ bet.bet.sum.toFixed(2) }}</span></p>
            </div>
          </div>
          <div class="inner">
            <div class="history_goods_2">
              <div class="history_good tooltip" :style="`border-bottom: 2px solid #${item.background_color};`" v-for="item of bet.bet.items" :key="item.assetid">
                <div class="img_box">
                  <img
                      :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.icon_url}/360fx360f`"
                      alt="">
                </div>
                <div class="descript">
                  <p>${{ item.price.toFixed(2) }}</p>
                </div>
                <div class="tooltiptext">
                  {{ item.market_hash_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Fair :show-fair.sync="showFair" :game.sync="game" />
  </div>
</template>

<script>
import Fair from "@/components/modals/Fair";
export default {
  components: {Fair},
  data() {
    return {
      game: null,
      bets: {},
      showFair: false
    }
  },
  mounted() {
    this.getGame()
  },
  methods: {
    getGame() {
      this.$root.request('GET', `/games/gameById/${this.$route.params.id}`)
          .then(data => {
            this.game = data.game
            this.bets = data.bets
          })
          .catch(() => {
            this.$router.back()
          })
    }
  }
}
</script>