<template>
  <div class="right" v-if="$root.user !== null">
    <div class="z_wrapp">
      <div class="history_wrapp">
        <div class="history_title resp_hide">
          <div class="h_wrapp">
            <div class="hwrapp_inner"><i class="user_2"></i>
              <h3>Profile of {{ $root.user.username }}</h3></div>
          </div>
        </div>
        <div class="profile_templ">
          <div class="left">
            <div>
              <div class="user_param">
                <div class="big_avatar_wrapp">
                  <button @click="logOut" type="button" class="del_btn"></button>
                  <div class="img_box"><img style="width:114px;"
                                            :src="$root.user.avatar"
                                            alt=""></div>
                </div>
               <div style="display: flex;justify-content: center;">
                  <div :style="{border: '1px solid #2d324a', height: '20px',background: 'linear-gradient(45deg, #500, #f33)',width: '130px','border-radius': '4px','background-position-x': (-130)+($root.user.exp/100*130)+'px','background-repeat': 'no-repeat'}"></div>
                  </div>
            </div>
              </div>
          </div>
          <div class="right">
            <div class="profile_thumbnails_wrapp">
              <div class="profile_thumbnails">
                <div class="profile_thumb">
                  <div class="draw_item">
                    <div><img src="img/dubl_3.svg" alt=""></div>
                    <div><h3>total played</h3>
                      <p>{{ $root.user.total_played }}</p></div>
                  </div>
                </div>
                <div class="profile_thumb">
                  <div class="draw_item">
                    <div><img src="img/cup_3.svg" alt=""></div>
                    <div><h3>total wins</h3>
                      <p>{{ $root.user.total_wins }}</p></div>
                  </div>
                </div>
                <div class="profile_thumb">
                  <div class="draw_item">
                    <div><img src="img/cub.svg" alt=""></div>
                    <div><h3>items</h3>
                      <p id="profile_items_count">{{ $root.user.total_items }}</p></div>
                  </div>
                </div>
                <div class="profile_thumb">
                  <div class="draw_item">
                    <div><img src="img/rub_2.svg" alt=""></div>
                    <div><h3>total won</h3>
                      <p>{{ $root.user.total_win.toFixed(2) }}</p></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="profile_form_wrapp">
              <div class="profile_form_header">
                <div>
                  <div class="h_wrapp_2 h_wrapp_2_2"><i class="link"></i>
                    <h3>TRADE-LINK</h3></div>
                </div>
                <div class="s_hide"><a
                    href="https://steamcommunity.com/id/id/tradeoffers/privacy#trade_offer_access_url"
                    target="_blank" class="grey_link">Where to find?</a></div>
              </div>
              <form id="trade_url_form" class="profile_form" onclick="return false;">
                <div class="col_1">
                  <div class="input_wrapp_3"><input type="text" name="trade_url"
                                                    v-model="$root.user.trade_url"
                                                    placeholder="Your tradeoffer link..."></div>
                </div>
                <div class="col_2">
                  <button @click="saveUrl" type="submit" class="red_pill red_pill_2"><span>save</span></button>
                </div>
              </form>
              <p class="resp_link s_visible"><a
                  href="https://steamcommunity.com/id/id/tradeoffers/privacy#trade_offer_access_url" target="_blank"
                  class="grey_link">Where to find?</a></p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logOut() {
      this.$cookie.delete('token');
      this.$root.user = null;
      this.$router.push('/');
    },
    saveUrl() {
      this.$root.request('POST', '/users/saveTradeUrl', {
        url: this.$root.user.trade_url
      }).then(() => {
        this.$root.showNotify('success', 'Trade-url saved');
      }).catch((e) => {
        this.$root.showNotify('error', e);
      });
    }
  }
}
</script>
