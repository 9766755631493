<template>
  <div id="selectedBet" class="popup_deposit modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog modal-lg">
      <div class="popup_deposit_ins" v-if="selectedBet">
        <div class="popup_deposit_top">{{ selectedBet.user.username }}</div>

        <div class="popup_deposit_row text_wrapp_3" style="display: block;">
          <p>Написать в чат:</p>
          <input v-model="message" />
          <a style="width: 100%;margin-top: 5px;" class="popup_deposit_refresh" @click="sendMessage()">Отправить сообщение</a>
          <br/>
          <button style="cursor:pointer;width: 100%;" class="popup_deposit_depos" @click="setWinner()">Подкрутить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: ['selectedBet'],
  data() {
      return {
        message: ''
      }
  },
  mounted() {
    $('#selectedBet').on('hidden.bs.modal', function () {
      $('#selectedBet').modal('hide');
    })
  },
  methods: {
    sendMessage() {
      this.$root.request('POST', '/admin/bots/sendMessage', {
        bot_id: this.selectedBet.user.id,
        message: this.message
      }).then(() => {
        this.$root.showNotify('success', 'Сообщение отправлено');
        $('#selectedBet').modal('hide');
      })
    },
    setWinner() {
      this.$root.request('POST', `/admin/setWinner/${this.selectedBet.user.id}`)
          .then(() => {
            this.$root.showNotify('success', 'Победитель выбран');
            $('#selectedBet').modal('hide');
          })
    }
  }
}
</script>