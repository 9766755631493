import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/pages/index/Index";
import Auth from "@/pages/auth/Auth";
import Profile from "@/pages/profile/Profile";
import History from "@/pages/history/History";
import Top from "@/pages/top/Top";
import Support from "@/pages/support/Support";
import Raffle from "@/pages/raffle/Raffle";
import Game from "@/pages/game/Game";
import Faq from "../pages/faq/Faq";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/top',
    name: 'Top',
    component: Top
  },
  {
    path: '/Support',
    name: 'Support',
    component: Support
  },
  {
    path: '/Raffle',
    name: 'Raffle',
    component: Raffle
  },
  {
    path: '/game/:id',
    name: 'Game',
    component: Game
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
