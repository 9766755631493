<template>
  <div class="left" id="chatMenu" style="top: 88px;">
    <div class="sidebar">
      <div class="sidebar_header">
        <div class="col_1" style="position: relative;">
          <a
              v-if="$cookie.get('sound') == 1" @click="setSound"
              style="cursor: pointer;"
              class="sound sound_on"
          ></a>
          <a
              v-if="typeof $cookie.get('sound') === 'undefined' || $cookie.get('sound') == 0"
              @click="setSound"
              style="cursor: pointer;"
              class="sound sound_off"
          ></a>
          <div class="onl_ch">
            <div><i class="chat"></i></div>
            <div><p>ONLINE-CHAT</p></div>
          </div>
        </div>
        <div class="col_2">
          <button type="button" class="red_pill close_chat"><span>close</span></button>
          <div class="count_chat_users"><i class="green_light"></i>
            <p id="current_online">{{ online }}</p></div>
        </div>
      </div>
      <div class="chat_users" id="chatUsers">
          <div :class="`user_item ${msg.user.role}`" v-for="msg in chat" :key="msg.id">
            <div class="col">
              <div class="user_avatar_wrapp">
                <div class="user_avatar">
                  <a target="_blank">
                    <div class="img_box">
                      <img style="width: 34px;" :src="msg.user.avatar" alt="" />
                      <div class="lvl">{{ msg.user.lvl }}</div>
                    </div>
                  </a>
                </div>
                <p v-if="msg.user.role === 'moderator'">MODER</p>
                <p v-if="msg.user.role === 'admin'">ADMIN</p>
              </div>
            </div>
            <div class="col">
              <div class="user_text">
                <div class="user_text_header">
                  <div>
                    <h4>
                      <a target="_blank">{{ msg.user.username }}</a>
                      <span v-if="$root.user !== null && ($root.user.role === 'admin' || $root.user.role === 'moderator')">
                        (<a @click="deleteMessage(msg.id)" style="cursor: pointer;">Delete</a>
                        /
                        <a @click="banChatUser(msg.user.id)" style="cursor: pointer;">Ban</a>)
                      </span>
                    </h4>
                  </div>
                  <div>
                    <p></p>
                  </div>
                </div>
                <div class="user_text_content">
                  <p>{{ msg.message }}</p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="form_wrapp resp_form_wrapp">
      <form id="chat_resp_form" onsubmit="return false;">
        <div class="form">
          <div class="col">
            <div class="input_wrapp_2 form_input">
              <div class="show_field"></div>
              <textarea v-model="message" class="formInput" placeholder="Your message..." name=""></textarea>
              <div class="count_symbols" data-maxsimbols="100">{{ 100 - message.length }}</div>
            </div>
          </div>
          <div class="col">
            <button type="button" class="submit_btn" @click="sendMessage"></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  props: ['online'],
  data() {
    return {
      chat: [],
      message: ''
    }
  },
  watch: {
    'message'(newValue, oldValue) {
      if (newValue.length > 100) {
        this.message = oldValue;
      }
    }
  },
  mounted() {
    this.getChat();
  },
  methods: {
    getChat() {
      this.$root.request('GET', '/chat/get')
        .then((data) => {
          this.chat = data;

          this.scrollTop();
        })
    },
    sendMessage() {
      this.$root.request('POST', '/chat/send', {
        message: this.message
      }).then(() => {
        this.$root.showNotify('success', 'Message sent');
        this.message = '';
      }).catch((e) => {
        this.$root.showNotify('error', e);
      });
    },
    scrollTop() {
      setTimeout(() => {
        $('.chat_users').animate({ scrollTop: 9999999 });
      }, 1);
    },
    setSound() {
      if (this.$cookie.get('sound') == 1) {
        this.$cookie.set('sound', 0);
      } else {
        this.$cookie.set('sound', 1);
      }
      this.$forceUpdate();
    },
    deleteMessage(id) {
      this.$root.request('POST', '/chat/delete', {
        id
      }).then(() => {
        this.$root.showNotify('success', 'Message deleted');
      }).catch((e) => {
        this.$root.showNotify('error', e);
      });
    },
    banChatUser(id) {
      this.$root.request('POST', '/chat/ban', {
        id
      }).then(() => {
        this.$root.showNotify('success', 'User banned');
      }).catch((e) => {
        this.$root.showNotify('error', e);
      });
    }
  },
  sockets: {
    chatNewMessage(data) {
      this.chat.push(data);
      this.$forceUpdate();

      this.scrollTop();
    },
    deleteMessage(id) {
      const index = this.chat.findIndex(x => x.id === id);

      if (index > -1) {
        this.chat.splice(index, 1);
        this.$forceUpdate();

        this.scrollTop();
      }
    },
    chatClear() {
      this.chat = [];
      this.$forceUpdate();

      this.scrollTop();
    }
  }
}
</script>
