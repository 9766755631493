<template>
  <footer class="footer_section">
    <div class="footer_templ">
      <div class="left" id="footerForm">
        <div class="form_wrapp">
          <form id="chat_full_form" onsubmit="return false;">
            <div class="form">
              <div class="col">
                <div class="input_wrapp_2 form_input">
                  <div class="show_field"></div>
                  <textarea class="formInput" v-model="message" placeholder="Your message..." name=""></textarea>
                  <div class="count_symbols" data-maxsimbols="100">{{ 100 - message.length }}</div>
                </div>
              </div>
              <div class="col">
                <button @click="sendMessage" type="submit" class="submit_btn"></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="right">
        <div class="two_cols_3">
          <div class="col">
            <div class="text_wrapp"><p>RUSTYPUNCH.COM - copying the site design, elements or content is strictly prohibited.
              All scripts and graphic elements are the property of the site administration.</p></div>
          </div>
          <div class="col">

          </div>
        </div>
      </div>
    </div>
    <div class="resp_footer">
      <ul class="resp_nav">
        <li><router-link to="/">
          <div class="icon_box"><i class="star visible"></i><i class="star_red hover"></i></div>
          <p>Jackpot</p></router-link></li>
        <li><router-link to="/top">
          <div class="icon_box"><i class="cup_resp visible"></i><i class="cup_resp_red hover"></i></div>
          <p>Top</p></router-link></li>
        <li><a href="#" class="chat_link">
          <div class="icon_box"><i class="chat_resp visible"></i><i class="chat_resp_red hover"></i></div>
          <p>Chat</p></a></li>
        <li><a href="#" class="more_link">
          <div class="more_dots"><span></span><span></span><span></span></div>
          <p>More</p></a></li>
      </ul>
    </div>
  </footer>
</template>

<script>
import $ from 'jquery';

export default {
  data() {
    return {
      message: ''
    }
  },
  watch: {
    'message'(newValue, oldValue) {
      if (newValue.length > 100) {
        this.message = oldValue;
      }
    }
  },
  mounted() {
    $(".more_link").click(function(e) {
      e.preventDefault();
      if ($("#resp_nav").is(":hidden")) {
        $("#resp_nav").fadeIn(300);
        $(this).addClass("active");
      } else {
        $("#resp_nav").fadeOut(300);
        $(this).removeClass("active");
      }
    });

    $(".close_nav").on("click", function(e) {
      e.preventDefault();
      $("#resp_nav").fadeOut(300);
    });

    $(".chat_link").click(function(e) {
      e.preventDefault();
      if ($("#chatMenu").is(":hidden")) {
        $("#chatMenu").fadeIn(300);
        $(this).addClass("active");
      } else {
        $("#chatMenu").fadeOut(300);
        $(this).removeClass("active");
      }
    });

    $(".close_chat").on("click", function(e) {
      e.preventDefault();
      $("#chatMenu").fadeOut(300);
    });
  },
  methods: {
    sendMessage() {
      this.$root.request('POST', '/chat/send', {
        message: this.message
      }).then(() => {
        this.$root.showNotify('success', 'Message sent');
        this.message = '';
      }).catch((e) => {
        this.$root.showNotify('error', e);
      });
    }
  }
}
</script>
