<template>
  <div class="right">
    <div class="z_wrapp">
      <div class="support_wrapp support_2">
        <div class="support_header">
          <div class="col_1">
            <div class="h_wrapp_2">
              <i class="settings_red_big"></i>
              <h3>support</h3>
            </div>
          </div>
          <div class="col_2">
            <a @click="state = 'create'" style="cursor: pointer;" data-action="support_new_ticket" class="green_pill">
              <i class="notice"></i>
              <span>create new ticket</span>
            </a>
          </div>
          <div class="resp_col">
            <div class="user_avatar blue">
              <div class="img_box">
                <img src="img/avatar.jpg" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="chat_templ">
          <div class="chat_col_1" id="ticketsSidebar">
            <button type="button" class="red_pill close_tickets">
              <span>close</span>
            </button>
            <div class="chat_users_2" id="support_tickets">
              <div class="chat_user_wrapp" v-for="ticket in tickets" :key="ticket.id">
                <button v-if="ticket.is_closed" @click="deleteTicket(ticket.id)" type="button" class="icon_btn inner_btn">
                  <i class="grey_trash visible"></i>
                  <i class="red_trash hover"></i>
                </button>
                <span v-if="!ticket.is_closed" class="inner_elem">
                  <i class="green_circle"></i>
                </span>
                <a @click="setShowTicket(ticket.id)" style="cursor: pointer" class="chat_user">
                  <div>
                    <div class="user_avatar blue">
                      <div class="img_box"><img src="img/avatar.jpg" alt="" class=""></div>
                    </div>
                  </div>
                  <div>
                    <div class="chat_user_header">
                      <div><h3>{{ ticket.subject }}</h3></div>
                      <div>
                        <div class="chat_user_inline">
                          <div v-if="!ticket.is_closed">
                            <div class="status_pill green"><p>active</p></div>
                          </div>
                          <div v-else-if="ticket.is_closed">
                            <div class="chat_user_inline">
                              <div>
                                <div class="status_pill red"><p>Closed</p></div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div class="chat_user_content">
                      <div><p>{{ JSON.parse(ticket.messages)[0].message }}</p></div>
                      <div><p>{{ pad(new Date(ticket.created_at).getHours()) }}:{{ pad(new Date(ticket.created_at).getMinutes()) }}</p></div>
                    </div>
                  </div>
                </a></div>
            </div>
          </div>
          <div class="chat_col_2" id="support_index" v-if="state === 'all'">
            <div class="chat_box">
              <div class="resp_header">
                <a @click="state = 'all'" style="cursor: pointer;" class="green_pill resp_green_pill show_tickets">
                  <span>View tickets</span>
                </a>
                &nbsp;&nbsp;&nbsp;
                <a @click="state = 'create'" style="cursor: pointer;" data-action="support_new_ticket"
                   class="green_pill">
                  <i class="notice"></i>
                  <span>create new ticket</span>
                </a>
              </div>
              <div class="chat_empty">
                <i class="chat_icon"></i>
                <p>
                  Please, select ticket or <a @click="state = 'create'" style="cursor: pointer;" class="red_link">create
                  new</a>
                </p>
              </div>
            </div>
          </div>
          <div class="chat_col_2" id="support_new_ticket" v-if="state === 'create'">
            <div class="chat_box">
              <div class="resp_header">
                <a @click="state = 'all'" style="cursor: pointer;" class="green_pill resp_green_pill show_tickets">
                  <span>View tickets</span>
                </a>
                &nbsp;&nbsp;&nbsp;
                <a @click="state = 'create'" style="cursor: pointer;" data-action="support_new_ticket"
                   class="green_pill">
                  <i class="notice"></i>
                  <span>create new ticket</span>
                </a>
              </div>
              <div class="ticket_wrapp">
                <div class="ticket_header">
                  <h2>Create ticket</h2>
                </div>
                <form class="ticket_form_wrapp" onsubmit="return false;">
                  <div class="ticket_form">
                    <div class="input_wrapp_3">
                      <input type="text" name="subject" placeholder="Subject..." v-model="newTicket.subject">
                    </div>
                    <div class="input_wrapp_3">
                      <input type="text" name="subject" placeholder="Game number..." v-model="newTicket.gameNumber">
                    </div>
                    <div class="input_wrapp_3">
                      <textarea name="message" placeholder="Describe your problem"
                                v-model="newTicket.problem"></textarea>
                    </div>
                    <div class="submit_wrapp">
                      <div class="inline_3">
                        <div></div>
                        <div>
                          <button type="submit" class="red_pill red_pill_2" @click="createTicket">
                            <span>Submit ticket</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="chat_col_2" id="support_view_ticket" v-if="state === 'show'">
            <div class="chat_box">
              <div class="resp_header">
                <a @click="state = 'all'" class="green_pill resp_green_pill show_tickets">
                  <span>View tickets</span>
                </a>
                &nbsp;&nbsp;&nbsp;
                <a @click="state = 'create'" data-action="support_new_ticket" class="green_pill">
                  <i class="notice"></i>
                  <span>create new ticket</span>
                </a>
              </div>
              <div class="chat_header">
                <div class="chat_header_col_1">
                  <div class="inline_2">
                    <div>
                      <h2 data-field="subject">{{ showTicket.subject }}</h2>
                    </div>
                    <div>
                      <div data-field="status" class="status_pill" :class="[showTicket.is_closed ? 'red' : 'green']">
                        <p v-if="showTicket.is_closed">Closed</p>
                        <p v-if="!showTicket.is_closed">Active</p>
                      </div>
                    </div>
                    <div data-field="closeticket">
                      <a @click="closeTicket" style="cursor: pointer;" class="icon_link" v-if="!showTicket.is_closed">
                        <span class="icon_btn">
                          <i class="grey_trash visible"></i>
                          <i class="red_trash hover"></i>
                        </span>
                        <span>Close ticket</span>
                      </a>
                    </div>
                  </div>
                  <div class="time_row">
                    <p data-field="date">Game number: #{{ showTicket.game_number }}</p>
                  </div>
                  <div class="time_row">
                    <p data-field="date">{{ parseData(showTicket.created_at) }}</p>
                  </div>
                </div>
                <div class="chat_header_col_2">
                  <div class="user_wrapp_2">
                    <div>
                      <h3>RUSTYPUNCH</h3>
                      <p>SUPPORT TEAM</p>
                    </div>
                    <div>
                      <div class="user_avatar blue">
                        <div class="img_box">
                          <img src="img/avatar.jpg" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat_messages">
                <div data-field="messages">
                  <div class="chat_message_wrapp" :class="[msg.type === 'user' ? 'right_m' : 'left_m']" v-for="(msg, i) in JSON.parse(showTicket.messages)" :key="i">
                    <div class="chat_message">
                      <p>{{ msg.message }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat_footer">
                <div class="chat_form_wrapp" v-if="!showTicket.is_closed" data-field="replyform">
                  <form onsubmit="return false;">
                    <div class="chat_form">
                      <div class="chat_form_col_1">
                        <div class="input_wrapp_3">
                          <input type="text" name="" v-model="ticketMessage" placeholder="Your message...">
                        </div>
                      </div>
                      <div class="chat_form_col_2" @click="sendMessage">
                        <button type="submit" class="submit_btn"></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div v-else-if="showTicket.is_closed" data-field="closedform">
                  <p>You cannot reply to closed tickets.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import date from 'date-and-time';

export default {
  data() {
    return {
      state: 'all',
      newTicket: {
        subject: '',
        problem: '',
        gameNumber: ''
      },
      showTicket: {},
      ticketMessage: '',
      tickets: []
    }
  },
  watch: {
    '$root.user'() {
      this.getTickets();
    }
  },
  mounted() {
    if (!this.$cookie.get('token')) {
      this.$root.showNotify('error', 'Log in to view the page');

      return this.$router.back();
    }

    this.getTickets();
  },
  methods: {
    scrollTop() {
      $('#support_tickets').mCustomScrollbar('destroy');

      setTimeout(() => {
        $('#support_tickets').mCustomScrollbar({
          setTop: "0px"
        });
      }, 500)
    },
    createTicket() {
      this.$root.request('POST', '/tickets/create', {
        subject: this.newTicket.subject,
        problem: this.newTicket.problem,
        gameNumber: this.newTicket.gameNumber
      }).then((tickets) => {
        this.newTicket = {
          subject: '',
          problem: ''
        };

        this.$root.showNotify('success', 'Ticket created')

        this.tickets = tickets;
        this.state = 'all';

        this.scrollTop();
      }).catch((err) => {
        this.$root.showNotify('error', err);
      })
    },
    getTickets() {
      this.$root.request('GET', '/tickets/getTickets')
        .then((tickets) => {
          this.tickets = tickets;
          this.$forceUpdate();

          this.scrollTop();
        })
    },
    setShowTicket(id) {
      this.$root.request('GET', `/tickets/get/${id}`)
        .then((ticket) => {
          this.state = 'show';

          this.showTicket = ticket;
        }).catch((err) => {
          this.$root.showNotify('error', err);
        })
    },
    sendMessage() {
      this.$root.request('POST', '/tickets/sendMessage', {
        id: this.showTicket.id,
        message: this.ticketMessage
      })
        .then((ticket) => {
          this.$root.showNotify('success', 'Message send');

          this.ticketMessage = '';
          this.showTicket = ticket;
        })
        .catch((e) => {
          this.$root.showNotify('error', e);
        })
    },
    closeTicket() {
      this.$root.request('POST', '/tickets/closeTicket', {
        id: this.showTicket.id
      })
          .then((ticket) => {
            this.$root.showNotify('success', 'Ticket closed');

            const index = this.tickets.findIndex(x => x.id === ticket.id);

            if (index > -1) {
                this.tickets[index] = ticket;
                this.$forceUpdate();
            }

            this.showTicket = ticket;
          })
          .catch((e) => {
            this.$root.showNotify('error', e);
          })
    },
    deleteTicket(id) {
      this.$root.request('POST', '/tickets/deleteTicket', {
        id
      })
        .then(() => {
          this.$root.showNotify('success', 'Ticket deleted');

          if (typeof this.showTicket.id !== 'undefined' && this.showTicket.id === id) {
            this.state = 'all';
          }

          const index = this.tickets.findIndex(x => x.id === id);

          if (index > -1) {
            this.tickets.splice(index, 1);
            this.$forceUpdate();
          }
        })
        .catch((e) => {
          this.$root.showNotify('error', e);
        })
    },
    parseData(createdAt) {
      return date.format(new Date(createdAt), 'YYYY-MM-DD HH:mm:ss')
    },
    pad(n) {
      if (n < 10)
        return "0" + n;
      return n;
    }
  }
}
</script>
