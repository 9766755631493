<template>
  <div class="resp_menu" id="resp_nav">
    <button type="button" class="close_nav"></button>
    <ul class="resp_nav_list">
      <li><a @click="redirectTo('Support')">Support</a></li>
      <li><a @click="redirectTo('History')">Game history</a></li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "ResponsiveMenu",
  methods: {
    redirectTo(page) {
      $("#resp_nav").fadeOut(300);

      this.$router.push({ name: page });
    }
  }
}
</script>
