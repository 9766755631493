<template>
  <div class="right top_bg" v-if="giveaway">
    <div class="z_wrapp">
      <div class="history_wrapp">
        <div class="history_title">
          <div class="h_wrapp">
            <div class="hwrapp_inner">
              <i class="price"></i>
              <h3>giveaway</h3>
            </div>
          </div>
        </div>

        <div class="users_slider_wrapp" v-if="isRoulette">
          <img class="slider_shape" src="img/slider_shape.png" alt="">
          <div class="users_slider slick-initialized slick-slider">
            <div class="slick-list draggable" id="jackpot_carousel">
              <div class="slick-track"
                   style="opacity: 1; width: 180000px;">
                <div class="slick-slide" v-for="(avatar, i) in avatars" :key="i">
                  <div>
                    <div class="slide" style="width: 100%; display: inline-block;">
                      <div class="user_avatar_3">
                        <div class="img_box">
                          <img :src="avatar.avatar" alt="" class="mCS_img_loaded">
                        </div>
                        <p class="sl_p">{{ avatar.chance }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="total_winner" v-if="showWinner">
          <div class="total_winner_header">
            <h3>winner:</h3>
            <h2>
              <a target="_blank">
                {{ giveaway.winner.username }}
              </a>
            </h2>
          </div>
          <div class="winner_three_cols">
            <div>
              <div class="total_winner_item">
                <h3>win:</h3>
                <p>{{ giveaway.item.price }}$</p>
              </div>
            </div>
            <div>
              <div class="total_winner_avatar">
                <a target="_blank">
                  <div class="img_box">
                    <img :src="giveaway.winner.avatar" alt="">
                  </div>
                </a>
              </div>
            </div>
            <div>
              <div class="total_winner_item">
                <h3>with a chance:</h3>
                <p>{{ giveaway.winner_chance }}%</p>
              </div>
            </div>
          </div>
        </div>

        <div class="three_cols">
          <div>
            <div class="items">
              <div class="draw_item">
                <div>
                  <img src="img/cub.svg" alt="">
                </div>
                <div>
                  <h3>item</h3>
                  <p>{{ giveaway.item.market_hash_name }}</p>
                </div>
              </div>
              <div class="draw_item">
                <div>
                  <img src="img/user.svg" alt="">
                </div>
                <div>
                  <h3>members</h3>
                  <p>{{ giveaway.users }}</p>
                </div>
              </div>
              <div class="draw_item">
                <div>
                  <img src="img/clock.svg" alt="">
                </div>
                <div>
                  <h3>before the giveaway</h3>
                  <p v-if="!giveaway.is_finished">
                    <countdown :time="(+ new Date(giveaway.end_time)) - (+ new Date())" :transform="transformDate">
                      <template slot-scope="props">{{ props.days }}:{{ props.hours }}:{{
                          props.minutes
                        }}:{{ props.seconds }}
                      </template>
                    </countdown>
                  </p>
                  <p v-else>
                    00:00:00:00
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="raffle_case_wrapp">
              <img class="fire_img" src="img/fire_2.png" alt="">
              <div class="img_box">
                <img :src="`https://steamcommunity-a.akamaihd.net/economy/image/${giveaway.item.icon_url}/128fx128f`"
                     alt="">
              </div>
              <div class="button_wrapp" v-if="!giveaway.is_finished">
                <a @click="joinGiveaway" class="red_pill"><span>participate</span></a>
              </div>
            </div>
          </div>
          <div>
            <div v-if="lastGiveaway">
              <div class="gamer_params_wrapp gamer_params_wrapp_1">
                <div>
                  <div class="gamer_params gamer_params_1">
                    <div>
                      <div class="single_item">
                        <div class="draw_item">
                          <div>
                            <i class="cup_red_big"></i>
                          </div>
                          <div>
                            <h3>the last winner</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="items_2">
                        <div class="draw_item">
                          <div>
                            <i class="percent_red_big"></i>
                          </div>
                          <div>
                            <h3>chance</h3>
                            <p>{{ lastGiveaway.winner_chance }} %</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="user_wrapp_2">
                        <div>
                          <h3>
                            <a target="_blank">
                              {{ lastGiveaway.winner.username }}
                            </a>
                          </h3>
                        </div>
                        <div>
                          <div class="user_avatar red">
                            <a  target="_blank">
                              <div class="img_box">
                                <img :src="lastGiveaway.winner.avatar" alt="" style="width: 38px;">
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="items_wrapp">
          <div class="items_wrapp_3">
            <div class="item_3">
              <div>
                <div class="number">
                  <p>1</p>
                </div>
              </div>
              <div>
                <h3>Condition</h3>
                <p>Play one game on the site</p>
              </div>
            </div>
            <div class="item_3">
              <div>
                <div class="number">
                  <p>2</p>
                </div>
              </div>
              <div>
                <h3>Condition</h3>
                <p>Nickname RUSTYPUNCH.com</p>
              </div>
            </div>
          </div>
        </div>

        <div class="history_title">
          <div class="h_wrapp">
            <div class="hwrapp_inner">
              <i class="price"></i>
              <h3>previous winners</h3>
            </div>
          </div>
        </div>

        <div class="winners_thumbnails">
          <div class="winner_thumb" v-for="lastGiveaway in lastGiveaways" :key="lastGiveaway.id">
            <div class="winner_thumb_header">
              <div class="user_info_2">
                <div>
                  <div class="user_avatar_4">
                    <a target="_blank">
                      <div class="img_box">
                        <img :src="lastGiveaway.winner.avatar" alt="" style="width: 38px;">
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <h3>
                    <a target="_blank">
                      {{ lastGiveaway.winner.username }}
                    </a>
                  </h3>
                  <p>Chance: <span>{{ lastGiveaway.winner_chance }}%</span></p>
                </div>
              </div>
            </div>
            <div class="winner_thumb_content">
              <div class="img_box">
                <img
                    :src="`https://steamcommunity-a.akamaihd.net/economy/image/${lastGiveaway.item.icon_url}/128fx128f`"
                    alt="">
              </div>
              <div class="descript">
                <h3>item</h3>
                <p>{{ lastGiveaway.item.market_hash_name }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      giveaway: null,
      lastGiveaway: null,
      lastGiveaways: [],
      isRoulette: false,
      avatars: [],
      showWinner: false,
    }
  },
  mounted() {
    this.getGiveaway()
  },
  methods: {
    getGiveaway() {
      this.$root.request('GET', '/giveaways/giveaway')
          .then((data) => {
            this.giveaway = data.giveaway;
            this.lastGiveaway = data.lastFinishedGiveaway
            this.lastGiveaways = data.lastGiveaways;

            if (this.giveaway && this.giveaway.winner) {
              this.showWinner = true;
            }
          })
          .catch(() => {
            this.$router.back();
          })
    },
    joinGiveaway() {
      this.$root.request('POST', '/giveaways/join')
          .then(() => {
            this.$root.showNotify('success', 'You have joined the giveaway');
          })
          .catch((err) => {
            this.$root.showNotify('error', err);
          })
    },
    transformDate(props) {
      Object.entries(props).forEach(([key, value]) => {
        props[key] = value < 10 ? `0${value}` : value;
      });

      return props;
    },
    startRoulette(lastGiveaway, lastGiveaways) {
      this.isRoulette = true;

      setTimeout(() => {
        this.$root.playSound('/sound/carousel.mp3');

        let t = 80;

        let scrollValue = -t * 80 + ($('#jackpot_carousel').outerWidth() / 2) - this.randomInteger(20, 40);

        $('#jackpot_carousel > div').animate({
          marginLeft: scrollValue
        }, 12000, 'swing');

        setTimeout(() => {
          this.$root.playSound('/sound/winner.mp3');

          this.isRoulette = false;
          this.showWinner = true;
          this.lastGiveaway = lastGiveaway;
          this.lastGiveaways = lastGiveaways;
        }, 12500);
      }, 500);
    },
    randomInteger(min, max) {
      let rand = min + Math.random() * (max - min)
      rand = Math.round(rand);
      return rand;
    },
  },
  sockets: {
    updateGiveaway(giveaway) {
      this.giveaway = giveaway;
      this.$forceUpdate();
    },
    startRouletteGiveaway(data) {
      this.avatars = data.avatars;
      this.giveaway = data.giveaway;

      this.startRoulette(data.lastGiveaway, data.lastGiveaways);
    }
  }
}
</script>
