<template>
  <div class="right">
    <div class="history_wrapp">
      <div class="history_title">
        <div class="h_wrapp">
          <div class="hwrapp_inner"><i class="clock_2"></i>
            <h3>Game history</h3></div>
        </div>
      </div>
      <div>
        <div class="history_goods_wrapp" v-for="game in games" :key="game.id">
          <div class="history_header">
            <div class="col">
              <div class="user_info">
                <div class="user_avatar">
                  <a target="_blank">
                    <div class="img_box">
                      <img style="width:32px;"
                           :src="game.user.avatar"
                           alt="">
                      <div class="lvl">{{ game.user.lvl }}</div>
                    </div>
                  </a>
                </div>
                <div class="user_info_text">
                  <h3>
                    <a
                       target="_blank">{{ game.user.username }}</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="draws_items draws_items_2">
                <div class="draw_item draw_item_2">
                  <div></div>
                  <div><h3>game Id</h3>
                    <p>#{{ game.id }}</p></div>
                </div>
                <div class="draw_item draw_item_2">
                  <div><img src="img/cub_2.svg" alt=""></div>
                  <div><h3>items</h3>
                    <p>{{ game.items }}</p></div>
                </div>
                <div class="draw_item draw_item_2">
                  <div><img src="img/rub_2.svg" alt=""></div>
                  <div><h3>bank</h3>
                    <p>{{ game.bank.toFixed(2) }}</p></div>
                </div>
                <div class="draw_item draw_item_2">
                  <div><img src="img/percent.svg" alt=""></div>
                  <div><h3>chance</h3>
                    <p>{{ game.chance.toFixed(2) }}</p></div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="text_wrapp_2">
                <div style="text-align:right;">
                  <router-link tag="a" :to="{name: 'Game', params: {id: game.id}}" class="red_pill">
                    <span>Check game</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="history_goods">
            <div class="history_good tooltip" v-for="item in game.win_items" :key="item.assetid">
              <div class="img_box">
                <img
                    :src="`https://steamcommunity-a.akamaihd.net/economy/image/${item.icon_url}/330x192`"
                    alt="">
              </div>
              <div class="descript">
                <p>${{ item.price.toFixed(2) }}</p>
              </div>
              <div class="tooltiptext">
                {{ item.market_hash_name }}
              </div>
            </div>
          </div>
          <div class="draws_items draws_items_2 draws_items_resp">
            <div class="draw_item draw_item_2">
              <div><img src="img/cub_2.svg" alt=""></div>
              <div><h3>items</h3>
                <p>{{ game.items }}</p></div>
            </div>
            <div class="draw_item draw_item_2">
              <div><img src="img/rub_2.svg" alt=""></div>
              <div><h3>bank</h3>
                <p>{{ game.bank.toFixed(2) }}</p></div>
            </div>
            <div class="draw_item draw_item_2">
              <div><img src="img/percent.svg" alt=""></div>
              <div><h3>chance</h3>
                <p>{{ game.chance.toFixed(2) }}</p></div>
            </div>
          </div>
          <div class="text_wrapp_2 resp_text_wrapp">
            <div style="text-align:right;">
              <router-link tag="a" :to="{name: 'Game', params: {id: game.id}}" class="red_pill">
                <span>Check game</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      games: []
    }
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      this.$root.request('GET', '/games/history')
          .then((games) => {
            this.games = games;

            this.games.forEach((game) => {
              game.win_items = JSON.parse(game.win_items);

              game.win_items = game.win_items.sort(((e, t) => {
                return t.price - e.price
              }))
            })
          })
    }
  }
}
</script>