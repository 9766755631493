<template>
  <div class="right top_bg">
    <div class="z_wrapp">
      <div class="history_wrapp">
        <div class="history_title">
          <div class="h_wrapp">
            <div class="hwrapp_inner"><i class="cup_2"></i>
              <h3>Top players</h3></div>
          </div>
        </div>
        <div class="top_places">
          <div v-if="typeof users[0] !== 'undefined'" class="top_place top_place_1">
            <div class="inner">
              <div class="photo_bg_wrapp"><img class="photo_bg" src="img/one.png">
                <a target="_blank">
                  <div class="photo_box"><img style="width:145px;"
                                              :src="users[0].avatar"
                                              alt=""></div>
                </a>
              </div>
              <div class="descript">
                <h3>
                  <a style="font-size: 18px" target="_blank">
                    {{ users[0].username }}
                  </a>
                </h3>
                <div class="draw_items_wrapp">
                  <div class="draw_item draw_item_2">
                    <div><img src="img/dubl_2.svg" alt=""></div>
                    <div><h3>played</h3>
                      <p>{{ users[0].total_played }}</p></div>
                  </div>
                  <div class="draw_item draw_item_2">
                    <div><img src="img/rub_2.svg" alt=""></div>
                    <div><h3>won</h3>
                      <p>{{ users[0].total_win.toFixed(2) }}</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="typeof users[1] !== 'undefined'" class="top_place top_place_2">
            <div class="inner">
              <div class="photo_bg_wrapp"><img class="photo_bg" src="img/two.png">
                <a target="_blank">
                  <div class="photo_box"><img style="width:116px;"
                                              :src="users[1].avatar"
                                              alt=""></div>
                </a>
              </div>
              <div class="descript">
                <h3>
                  <a style="font-size: 18px" target="_blank">
                    {{ users[1].username }}
                  </a>
                </h3>
                <div class="draw_items_wrapp">
                  <div class="draw_item draw_item_2">
                    <div><img src="img/dubl_2.svg" alt=""></div>
                    <div><h3>played</h3>
                      <p>{{ users[1].total_played }}</p></div>
                  </div>
                  <div class="draw_item draw_item_2">
                    <div><img src="img/rub_2.svg" alt=""></div>
                    <div><h3>won</h3>
                      <p>{{ users[1].total_win.toFixed(2) }}</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="typeof users[2] !== 'undefined'" class="top_place top_place_3">
            <div class="inner">
              <div class="photo_bg_wrapp"><img class="photo_bg" src="img/three.png">
                <a target="_blank">
                  <div class="photo_box"><img style="width:116px;"
                                              :src="users[2].avatar"
                                              alt=""></div>
                </a>
              </div>
              <div class="descript">
                <h3>
                  <a style="font-size: 18px" target="_blank">
                    {{ users[2].username }}
                  </a>
                </h3>
                <div class="draw_items_wrapp">
                  <div class="draw_item draw_item_2">
                    <div><img src="img/dubl_2.svg" alt=""></div>
                    <div><h3>played</h3>
                      <p>{{ users[2].total_played }}</p></div>
                  </div>
                  <div class="draw_item draw_item_2">
                    <div><img src="img/rub_2.svg" alt=""></div>
                    <div><h3>won</h3>
                      <p>{{ users[2].total_win.toFixed(2) }}</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table">
          <div class="table_row table_header">
            <div class="cell"><p>№</p></div>
            <div class="cell"><p>USER</p></div>
            <div class="cell"><p>WON TIMES</p></div>
            <div class="cell"><p>WON</p></div>
          </div>

          <div class="table_row" v-for="(user, i) in users.slice(3)" :key="user.id">
            <div class="cell"><p>{{ i + 4 }}</p></div>
            <div class="cell">
              <div class="user_info">
                <div class="user_avatar">
                  <a target="_blank">
                    <div class="img_box"><img style="width:32px;"
                                              :src="user.avatar"
                                              alt=""></div>
                  </a>
                </div>
                <div class="user_info_text">
                  <h3>
                    <a target="_blank">
                      {{ user.username }}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="cell"><p>{{ user.total_played }}</p></div>
            <div class="cell"><p>{{ user.total_win.toFixed(2) }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      place: 3
    }
  },
  mounted() {
    this.getTop();
  },
  methods: {
    getTop() {
      this.$root.request('GET', '/users/getTop')
        .then((users) => {
          this.users = users;
        })
    }
  }
}
</script>