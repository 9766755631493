<template>
  <router-link to="/raffle" tag="div" class="draw" v-if="giveaway">
    <div class="inline">
      <div class="draw_title">
        <div class="draw_title_col">
          <img src="img/arm.png" alt="">
        </div>
        <div class="draw_title_col">
          <h3>win cool prizes!</h3>
          <h2>big GIVEAWAY!</h2>
        </div>
      </div>
    </div>
    <div class="inline">
      <router-link tag="a" to="/raffle" class="green_pill"><span>PARTICIPATE</span></router-link>
    </div>
    <div class="inline">
      <div class="draws_items">
        <div class="draw_item">
          <div>
            <img src="img/rub_2.svg" alt="">
          </div>
          <div>
            <h3>sum</h3>
            <p>{{ giveaway.item.price }}$</p>
          </div>
        </div>
        <div class="draw_item">
          <div>
            <img src="img/clock.svg" alt="">
          </div>
          <div>
            <h3>before the giveaway</h3>
            <p>
              <countdown :time="(+ new Date(giveaway.end_time)) - (+ new Date())" :transform="transformDate">
                <template slot-scope="props">{{ props.days }}:{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</template>
              </countdown>
            </p>
          </div>
        </div>
        <div class="draw_item">
          <div>
            <img src="img/cub.svg" alt="">
          </div>
          <div>
            <h3>item</h3>
            <p>{{ giveaway.item.market_hash_name }}</p>
          </div>
        </div>
        <div class="draw_item">
          <div>
            <img src="img/user.svg" alt="">
          </div>
          <div>
            <h3>members</h3>
            <p>{{ giveaway.users }}</p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      giveaway: null
    }
  },
  mounted() {
    this.getGiveaway();
  },
  methods: {
    getGiveaway() {
      this.$root.request('GET', '/giveaways/giveaway')
        .then((data) => {
          this.giveaway = data.giveaway;
        })
    },
    transformDate(props) {
      Object.entries(props).forEach(([key, value]) => {
        props[key] = value < 10 ? `0${value}` : value;
      });

      return props;
    },
  }
}
</script>