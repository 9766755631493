<template>
  <div class="right">
    <div class="add-page faq-page">
      <div class="title">Frequently asked questions</div>
      <div>
        <div class="row">
          <div class="box">
            <div class="question">WHAT IS rustypunch.com? AND HOW DO THE GAMES WORK?</div>
            <div class="answer">rustypunch is a 3rd party service using rust items with their inital value to take
              place in one possible games on site. Jackpot is a game
              for as many players as possible. You deposit Rust Skins into the pot and will recieve a possible Win
              Percentage back depending on the amount you depoisted. The more you depoist the higher percentage you will
              obtain, therefore giving you the higher chance to win.
            </div>
          </div>
          <div class="box">
            <div class="question">HOW DO I PLAY?</div>
            <div class="answer">For Jackpot, click Deposit and choose as many skins as youd like to depoisit.</div>
          </div>
        </div>
        <div class="row">
          <div class="box">
            <div class="question">HOW DO I SETUP MY ACCOUNT TO JOIN THE GAME?</div>
            <div class="answer">Click `Login` in the top right hand corner of the screen.Once logged in, go to profile
              and follow the instruction on screen set your Steam Trade link. (Note: Your Steam Inventory MUST be set to
              Public to play on our site)
            </div>
          </div>
          <div class="box">
            <div class="question">WHY ISNT MY INVENTORY LOADING?</div>
            <div class="answer">Check you set your Steam Inventory to public. Make sure your Steam Trade link is
              properly set. If you think it is, double check and reset it! If youve just recieved new items. Refresh
              your inventory using the button in the left hand corner of the module. If you answered yes to all those
              questions and you inventory still doesnt load, please wait 10 minutes. If 10 minutes has surpassed and
              your inventory still wont load, please contact support using the buttons at the bottom of the page.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="box">
            <div class="question">HOW TO WITHDRAW YOUR WINNINGS.</div>
            <div class="answer">Trade offer will come within a minute. For withdraw you need to have 10 lvl</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
