import Vue from 'vue'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "@/router"
import App from './App.vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import io from "socket.io-client"
import Notifications from 'vue-notification'
import VueCountdown from '@chenfengyuan/vue-countdown';

const socket = io('https://api.rustypunch.com')

Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.use(VueSocketIOExt, socket)
Vue.use(Notifications)
Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`

new Vue({
  data() {
    return {
      user: null
    }
  },
  mounted() {
    if (this.$cookie.get('sound') === null) {
      this.$cookie.set('sound', 1);
    }

    if (this.$cookie.get('token')) {
      this.getUser();
    }

    setInterval(() => {
      if (this.$cookie.get('token')) {
        this.$socket.client.emit('login', this.user.id);
      }
    }, 60000 * 5);
  },
  methods: {
    getUser() {
      this.request('GET', '/users/profile')
          .then((user) => {
            this.user = user;

            this.$socket.client.emit('login', user.id);
          })
          .catch(() => {
            this.$cookie.delete('token');
          })
    },
    async request(type, uri, data = {}) {
      if (this.$cookie.get('token')) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookie.get('token');
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res, rej) => {
        try {
          let result = null;

          if (type === 'POST') {
            result = await this.$root.axios.post(uri, data)
          } else {
            result = await this.$root.axios.get(uri, { params: data } )
          }

          return res(result.data);
        } catch (e) {
          if (typeof e.response !== "undefined" && typeof e.response.data.message !== "undefined") {
            return rej(e.response.data.message);
          } else {
            return rej(false);
          }
        }
      });
    },
    showNotify(type, text) {
      let title = 'Success';

      if (type === 'error') {
        title = 'Error';
      } else if (type === 'warn') {
        title = 'Info';
      }

      this.$notify({
        title: title,
        type,
        text,
        ignoreDuplicates: true
      });
    },
    playSound(path) {
      if (this.$cookie.get('sound') == 1) {
        const audio = new Audio(path);
        audio.play();
      }
    }
  },
  sockets: {
    notify(data) {
      if (this.user !== null && this.user.steamId === data.steamId) {
        this.showNotify(data.type, data.text);
      }
    }
  },
  render: h => h(App),
  router
}).$mount('#app')
