<template>
  <header>
    <div class="header_site">
      <div class="left">
        <router-link to="/" class="logo">
          <img class="logo_img" width="195" src="/img/logo.svg" alt="">
          <img class="logo_shadow" src="/img/logo_shadow.svg" alt="">
        </router-link>
      </div>
      <div class="right">
        <div class="main_nav_wrapp">
          <ul class="main_nav">
            <li><router-link to="/"><i class="star_big visible"></i><i
                class="star_red_big hover"></i><span>Jackpot</span></router-link></li>
            <li><router-link to="/history"><i class="clock_big visible"></i><i class="clock_red_big hover"></i><span>Game history</span></router-link>
            </li>
            <li><router-link to="/support"><i class="settings_big visible"></i><i class="settings_red_big hover"></i><span>Support</span></router-link>
            </li>
            <li><router-link to="/top"><i class="cup_big visible"></i><i class="cup_red_big hover"></i><span>Top players</span></router-link>
            </li>
            <li>
              <router-link to="/faq">
                <i class="settings_big visible"></i>
                <i class="settings_red_big hover"></i>
                <span>FAQ</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="lang_wrapp lang_wrapp_2"></div>
        <div v-if="$root.user === null" class="steam_btn_wrapp" style="display:block;">
          <a style="cursor: pointer;" @click="logIn" class="steam_btn">
            <div><i class="steam_icon"></i></div>
            <div><p>Sign in through</p>
              <p>steam</p></div>
          </a>
        </div>
        <div v-else class="resp_toolbar resp_toolbar_2" style="display:flex;">
          <div>
            <router-link to="/profile" class="ava">
              <div class="img_box">
                <img style="width:28px;"
                     :src="$root.user.avatar"
                     alt="">
                <div class="lvl">{{ $root.user.lvl }}</div>
              </div>
            </router-link>
          </div>
          <div>
            <a @click="logOut" class="logout" style="cursor: pointer"></a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    logIn() {
      let width = 860;
      let height = 500;
      let left = (screen.width / 2) - (width / 2);
      let top = (screen.height / 2) - (height / 2);
      let windowOptions = `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`;
      let type = 'auth';

      window.open(`${process.env.VUE_APP_API_URL}/api/auth/steam`, type, windowOptions);

      window.addEventListener("message", this.initToken, false);
    },
    initToken(event) {
      if (event.data.length > 0) {
        const token = event.data.slice(7);

        this.$cookie.set('token', token, { domain: '.rustypunch.com', expires: '1Y' });
        this.$root.getUser();
      }
    },
    logOut() {
      this.$cookie.delete('token');
      this.$root.user = null;
      this.$router.push('/');
    }
  }
}
</script>
