<template>
  <div id="JackpotDeposit" class="popup_deposit modal fade" role="dialog" style="display: none;">
    <div class="modal-dialog modal-lg">
      <div class="popup_deposit_ins">
        <div class="popup_deposit_top">Jackpot deposit</div>
        <div class="popup_deposit_search">
          <form>
            <div class="popup_deposit_search_form">
              <input v-model="search" type="text" class="popup_deposit_inp" placeholder="Search your inventary">
              <button class="popup_deposit_btn">
                <img src="img/search.png" alt="">
              </button>
            </div>
          </form>
        </div>
        <div class="popup_deposit_row" v-if="loadInventory">
          <div class="loader">Loading...</div>
        </div>
        <div class="popup_deposit_row" v-else>
            <label
                v-for="item of inventoryFilteredList()"
                :key="item.assetid"
                :style="[typeof selectedInventory[item.assetid] !== 'undefined' ? {'border': '1px solid red'} : {}]"
                class="label_item">
              <input type="checkbox" name="item">
              <div class="popup_deposit_item" @click="selectInventory(item)" :style="`border-bottom: 2px solid #${item.background_color}`">
                <div class="popup_deposit_img">
                  <img :src="`https://steamcommunity-a.akamaihd.net/economy/image/${item.icon_url}/128fx128f`" :alt="item.market_hash_name">
                </div>
                <div class="popup_deposit_price">${{ item.price.toFixed(2) }}</div>
              </div>
            </label>
        </div>
        <div class="popup_deposit_bottom">
          <a href="#" @click="refreshInventory" class="popup_deposit_refresh">Refresh</a>
          <button style="cursor:pointer;" @click="depositItems" :disabled="depositBtn" class="popup_deposit_depos">Deposit $ {{ selectedPrice.toFixed(2) }}</button>
        </div>
        <div class="close_popup" data-dismiss="modal">
          <img src="img/x.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap';
import Swal from 'bootstrap-sweetalert'

export default {
  props: ['showDepositSkins'],
  data() {
    return {
      loadInventory: true,
      inventory: {},
      selectedInventory: {},
      selectedPrice: 0.00,
      depositBtn: false,
      search: ''
    }
  },
  mounted() {
    const vm = this;

    $('#JackpotDeposit').on('hidden.bs.modal', function () {
      vm.$emit('update:showDepositSkins', false);
    })
  },
  watch: {
    'showDepositSkins'() {
      if (this.showDepositSkins) {
        this.getInventory();
        $('#JackpotDeposit').modal('show');
      } else {
        this.selectedInventory = {};
        this.selectedPrice = 0.00;
        $('#JackpotDeposit').modal('hide');
      }
    }
  },
  methods: {
    getInventory() {
      this.loadInventory = true;

      this.$root.request('GET', '/users/inventory')
          .then((inventory) => {
            this.loadInventory = false;
            this.inventory = inventory;
          })
          .catch((err) => {
            this.$root.showNotify('error', err);
          })
    },
    refreshInventory() {
      this.loadInventory = true;

      this.$root.request('GET', '/users/inventory/refresh')
          .then(() => {
            this.selectedInventory = {};
            this.selectedPrice = 0.00;

            setTimeout(() => {
              this.getInventory()
            }, 1000);
          })
    },
    selectInventory(item) {
      if (typeof this.selectedInventory[item.assetid] !== "undefined") {
        delete this.selectedInventory[item.assetid];
        this.selectedPrice -= item.price;
      } else {
        this.selectedInventory[item.assetid] = item;
        this.selectedPrice += item.price;
      }

      this.$forceUpdate();

    },
    depositItems() {
      if (Object.keys(this.selectedInventory).length === 0) {
        return;
      }

      this.depositBtn = true;

      this.$root.request('POST', '/deposit/deposit', this.selectedInventory)
        .then((data) => {
          this.depositBtn = false;

          this.$emit('update:showDepositSkins', false);

          Swal({
            title: "Your tradeoffer is ready",
            text: "Accept to deposit items",
            type: "success",
            showCancelButton: true,
            confirmButtonText: "Browser",
            cancelButtonText: "Steam-client",
          }, (isConfirm) => {
            if (isConfirm) {
              window.open(`https://steamcommunity.com/tradeoffer/${data.offerId}/`, '_blank');
            } else {
              window.open(`steam://openurl/https://steamcommunity.com/tradeoffer/${data.offerId}/`);
            }
          });
        })
        .catch((err) => {
          this.depositBtn = false;

          Swal("Error", err, "error");
        })
    },
    inventoryFilteredList() {
      return this.inventory.filter((item) => {
        return this.search.toLowerCase().split(' ').every(v => item.market_hash_name.toLowerCase().includes(v));
      });
    }
  }
}
</script>
